import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <h1></h1>
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 className="heading-name">
                <strong className="main-name"> Acosta Productions </strong>
              </h1>
              <p style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </p>
            </Col>

            <p>&nbsp;&nbsp;&nbsp;</p>

            <Col>
              <h2>
               Andres <strong className="main-name"> Acosta </strong>
              </h2>
              <p>
                Andres Acosta is an adult actor and content creator.
                Andres now resides in Las Vegas, NV, where he practices his passion as a creator.
                He was nominated in the category of Favorite Indepedent Male Creator for the 2025 AVN Awards.
                In his free time, Andres enjoys lifting weights, boxing, inventing, programming, and being with family and friends.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
