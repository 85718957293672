import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";

function Scenes() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>

        <p style={{ color: "white" }}>
        <h3 style={{ fontSize: "2.5em" }}>
            <span className="purple"> Scene </span> Ideas
        </h3>

        <h5> Sex Therapy.</h5>
        <p> 
            This is a fan favorite.
            Therapy and Sex Therapy are two of the most popular search terms on PornHub.
            Viewers love the fantasy of having an erotic twist to therapy.
        </p>

        <h5> Maid. </h5>
        <p>
          Made scenes are by far one of the most popular for going viral.
          I want to work on creating scenes that will uniquely capture a large audience and earn millions of views.
        </p>

        <h5> Classic Sex Tape. </h5>
        <p> 
            These types of scenes can be fun for the creators and are usually very low budget and easy.
            The scene is as simple as setting up a tripod and having some fun.
            There is a large audience for this amateur style creation, that allows the creators to focus on having great sex.
        </p>

        <h5> Hotwife Breeding. </h5>
        <p> 
            This scene plays on the taboo of a married woman being impregnated by another man.
            Creampies are also one of the most searched finishes.
        </p>

        <h5> Professor and Student. </h5>
        <p>
            This is a classic scene that plays on the fantasy of having sex with the hot professor.
            It can also play on the Professor's fantasy of having sex with a hot student.
        </p>

        <h5> Girlfriend and Girlfriend's Mom. </h5>
        <p>
            This is a fan fantasy because many men have a desire to have sex with their girlfriend's mother.
            The apple doesn't fall far from the tree.
        </p>

        <h5> Tantric Massage. </h5>
        <p>
            This is a favorite for the female models. 
            It explores the ancient art of tantric ritual and massage to enlighten the mind to the erotic ecstacy of tantric bliss.
            It allows me to completely serve the women, with my full focus on her sensation and pleasure.
        </p>

        <h5> Internet Stranger Creampie. </h5>
        <p>
            This plays on the fantasy of unprotected sex with a stranger from the Internet.
            Breeding and creampie related content have great viral potential.
        </p>

        <h5> Masturbation Instrusion. </h5>
        <p>
            This plays on the fantasy of a beautiful woman walking in on a man while he's masturbating.
            This is a common scene in adult content and one that often goes viral.
        </p>

        <h5> Neighbor. </h5>
        <p>
            Many men have fantasies about hooking up with a hot neighbor.
            This scene captures the fantasy with the allure of suburban sexuality.
        </p>

        <h5> Princess Leia.  </h5>
        <p>
            Princess Leia in her iconic gold bikini is a sexual fantasy for hundreds of millions of men.
            I want to shoot parody adult content to play on this fantasy with a hardcore erotic twist.
        </p>

        <h5> Harry Potter.</h5>
        <p>
            This is one of the most searched parody categories on major porn websites.
            I read all the books and saw the movies as well, so this would be fun to explore.
        </p>

        <h5> Co-Workers </h5>
        <p>
            This is an increasingly popular fantasy for many people, to have sex with a co-worker.
            Playing on this fantasy is a great way to appeal to a wide and professional audience. 

        </p>

        </p>

      </Container>
    </Container>
  );
}

export default Scenes;
