import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Tilt from "react-parallax-tilt";
import Button from "react-bootstrap/Button";
import img from './myIMG.png';

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              Andres' <span className="purple"> Contact & Content </span> 
            </h1>
            <h2 style={{ fontSize: "1.2em" }}>
              Email: <span className="purple"> AndresAcostaXXX1@gmail.com </span> 
            </h2>
            <h2 style={{ fontSize: "1.2em" }}>
              Cash App: <span className="purple"> AndresAcostaXXX </span> 
            </h2>
      <p>
        <Button variant="primary" href="https://x.com/AndresAcostaXXX" target="_blank">
          {"X (Twitter)"}
        </Button>
      </p>
      <p>
        <Button variant="primary" href="https://onlyfans.com/andresacostaxxx" target="_blank">
          {"OnlyFans"}
        </Button>
      </p>
      <p>
        <Button variant="primary" href="https://www.manyvids.com/Profile/1008808511/andresacostaxxx/Store/Videos" target="_blank">
          {"ManyVids"}
        </Button>
      </p>
      <p>
        <Button variant="primary" href="https://www.xvideos.com/channels/andresacostaxxx" target="_blank">
          {"XVideos"}
        </Button>
      </p>
      <p>
        <Button variant="primary" href="https://www.pornhub.com/model/andres-xxx-acosta" target="_blank">
          {"PornHub"}
        </Button>
      </p>
      <p>
        <Button variant="primary" href="https://www.xiaohongshu.com/user/profile/6789d753000000000a03d7ff?xsec_token=YBvoQVXeiXb27IY6Y2LFGboAnzySv01DY_PeIWxhf-PFg=&xsec_source=app_share&xhsshare=CopyLink&appuid=6789d753000000000a03d7ff&apptime=1737088531&share_id=b38d2d4ad5f247ac8925e2f352287351" target="_blank">
          {"小红书国际版"}
        </Button>
      </p>
      <p>
        <Button variant="primary" href="https://www.youtube.com/@AndresAcostaXXX/featured" target="_blank">
          {"YouTube"}
        </Button>
      </p>
      <p>
        <Button variant="primary" href="https://bsky.app/profile/andresacostaxxx.bsky.social" target="_blank">
          {"Bluesky"}
        </Button>
      </p>
      <p>
        <Button variant="primary" href="https://chaturbate.com/p/andresacostaxxx/?tab=bio" target="_blank">
          {"Chaturbate"}
        </Button>
      </p>
      <p>
        <Button variant="primary" href="https://www.sheer.com/andresacostaxxx" target="_blank">
          {"Sheer"}
        </Button>
      </p>
      <p>
        <Button variant="primary" href="https://fansly.com/AndresAcostaXXX/posts" target="_blank">
          {"Fansly"}
        </Button>
      </p>


                
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={img} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;

