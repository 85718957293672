import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";

function About() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>

        <p style={{ color: "white" }}>
        <h3 style={{ fontSize: "2.5em" }}>
            <span className="purple"> About Andres' </span>  Content Creation
        </h3>

        <h4> Why I make adult content.</h4>
        <p>
            I make adult content because I genuinely enjoy exploring, experiencing, and creating a world of erotic fantasy.
            For me, I found that the best content creates an escape for the audience to a slightly different world.
            My goal is to create scenes that succeed for niche audiences at a global scale.
            I love meeting, connecting, and working with people in the adult industry.
            So, the most important thing to me is that the people I work with have a great experience.
        </p>

        <h4> How I make adult content.</h4>
        <p>
            I think chemistry is everything, so I like to do my best to build chemistry with my content partners.
            I like to focus on creating content that has viral potential, so I have the end product in mind when I'm shooting.
            Having sex on camera is fun, but my mind is usually focused on the end product and sales.
            That said, I am a great listener, and I like to get as much information as I can about likes, dislikes, and desires before hand.
            It is really important to me that my partner and I are comfortable with each other during the shoot.
            As such, I do my best to create and work in comfortable and collaborative enviornments.
        </p>
        <p>
            I am TTS GSP tested and require everyone I work with to be as well.
            I am currently only doing trade shoots, but may be open to paid shoots in the future.
            The main platforms I shoot for are PornHub, OnlyFans, Manyvids, and X. 
        </p>

        <h4> What kind of adult content I make.</h4>
        <p>
            I think the best content is created when both people are comfortable, creative, and having fun.
            Personally, I like to focus on niche areas, content that I connect with and that I enjoy making.
            I like to make hardcore adult content for the pornographic platforms, but I also like to make non-x rated content for other platforms.
            Ultimately, I want to create content that fans enjoy and that has viral potential.
        </p>

        </p>

      </Container>
    </Container>
  );
}

export default About;
