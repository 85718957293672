import React from "react";
import Typewriter from "typewriter-effect";
import './type.css';

function Type() {
  return (
    <div className="typewriter-text">
    <Typewriter
      options={{
        strings: [
          "Welcome to a place where...",
          "fantasy manifests sureal sensation...",
          "your body becomes unbound, and...",
          "you experience a pure erotic ecstacy.",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
      style={{ color: 'red' }}
    />
    </div>

  );
}

export default Type;
